<template>
  <v-container>
    <!-- ############################################################## -->
    <!-- Header -->
    <!-- ############################################################## -->
    <v-row class="mb-3" justify="center">
      <v-col justify="center">
        <v-icon color="primary" dense @click="goBackCampaign">
          {{ icons.mdiChevronLeft }}
        </v-icon>
        <span class="font-weight-black ml-1" style="font-size: 20px">
          {{ 'Need Approval' }}
        </span>
      </v-col>
    </v-row>
    <!-- ############################################################## -->
    <!-- Tabs -->
    <!-- ############################################################## -->
    <v-row>
      <v-col>
        <v-tabs v-model="tabSelected">
          <v-tab>Campaign Manager</v-tab>
          <v-tab>Manage Salary</v-tab>
          <v-tab text-capitalize> Manage Loan </v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <!-- ############################################################## -->
    <!-- Approve and Reject buttons -->
    <!-- ############################################################## -->
    <v-row v-if="loanSubmissionsSelected.length !== 0" class="mt-0">
      <!-- v-if="selected.length" -->
      <v-col class="my-7">
        <v-btn large class="me-3 text-capitalize" color="primary" @click="bulkApproveLoanSubmission()"> Approve </v-btn>
        <v-btn large color="error" class="text-capitalize" @click="bulkRejectLoanSubmission()"> Reject </v-btn>
      </v-col>
    </v-row>
    <!-- ############################################################## -->
    <!-- ############################################################## -->
    <!-- ############################################################## -->

    <!-- <v-dialog
      v-if="showAlert == true"
      v-model="showAlert"
      max-width="400"
    >
      <v-card>
        <v-alert
          dense
          text
          type="success"
        >
          Post successfully <strong>Approved</strong>
        </v-alert>
        <v-row
          justify="end"
          class="mb-2 mr-1"
        >
          <v-btn
            color="green darken-1"
            text
            @click="showAlert = false"
          >
            Close
          </v-btn>
        </v-row>
      </v-card>
    </v-dialog> -->

    <salary-approval v-if="tabSelected == 1" />

    <!-- ############################################################## -->
    <!-- Loan Approval Table -->
    <!-- ############################################################## -->
    <v-data-table
      v-if="tabSelected == 2"
      v-model="loanSubmissionsSelected"
      :headers="loanApprovalHeaders"
      :items="loanSubmissionsNeedApproval"
      :single-select="singleSelect"
      show-select
      class="elevation-1"
    >
      <template v-slot:item.totalLoan="{ item }">
        <span>{{ formatToIDRCurrency(item.totalLoan) }}</span>
      </template>
      <template v-slot:item.action="{ item }">
        <v-row>
          <!-- v-if="selected.length" -->
          <v-col class="my-4">
            <v-btn outlined class="me-3 text-capitalize" color="primary" @click="singleApproveLoanSubmission(item.id)">
              Approve
            </v-btn>

            <v-btn outlined color="error" class="text-capitalize" @click="singleRejectLoanSubmission(item.id)">
              Reject
            </v-btn>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.id="{ item }">
        <v-row>
          <v-dialog v-model="dialog" scrollable max-width="600px" :retain-focus="false">
            <template v-slot:activator="{ on }">
              <v-btn small outlined color="primary" v-on="on" @click="goToPreview(item)"> Preview Post </v-btn>
            </template>
            <v-card>
              <v-card-title>Preview Post</v-card-title>
              <v-divider></v-divider>
              <v-card-text style="height: 600px">
                <!-- Start tab -->
                <v-row justify="space-between" align-content="center" align="center">
                  <v-col cols="12" sm="auto">
                    <v-tabs>
                      <v-tab class="text-capitalize" @click="previewPost = 'WhatsApp'"> WhatsApp </v-tab>
                      <v-tab class="text-capitalize" @click="previewPost = 'Facebook'"> Facebook </v-tab>
                      <v-tab class="text-capitalize" @click="previewPost = 'Instagram'"> Instagram </v-tab>
                    </v-tabs>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-row justify="end" class="py-2">
                  <v-hover v-slot="{ hover }">
                    <v-btn
                      small
                      outlined
                      class="me-3"
                      :style="{ 'background-color': hover ? '#1F979E' : '#fff', color: hover ? '#fff' : '#1F979E' }"
                    >
                      <!-- @click="approvePost(item)" -->
                      Approve
                    </v-btn>
                  </v-hover>
                  <v-hover v-slot="{ hover }">
                    <v-btn
                      small
                      outlined
                      :style="{ 'background-color': hover ? '#FF4C51' : '#fff', color: hover ? '#fff' : '#FF4C51' }"
                    >
                      <!-- @click.stop="showRejectModal = true" -->
                      Reject
                    </v-btn>
                  </v-hover>
                  <!-- <reject-confirmation-dialog
                    v-model="showRejectModal"
                    :item="item"
                    :post-need-approve="postNeedApprove"
                    :get-post-templates="getPostTemplates"
                    :posts="selected"
                  /> -->
                  <v-btn color="primary" small text outlined class="ml-2" @click.stop="dialog = false"> Close </v-btn>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </template>
    </v-data-table>
    <!-- ############################################################## -->
    <!-- ############################################################## -->
    <!-- ############################################################## -->
  </v-container>
</template>
<script>
import useAppConfig from '@/@core/@app-config/useAppConfig'
import { mdiChevronLeft } from '@mdi/js'
import SalaryApproval from './SalaryApproval.vue'

export default {
  name: 'ManageApproval',
  setup() {
    const { isDark } = useAppConfig()

    return {
      icons: { mdiChevronLeft },
      isDark,
    }
  },
  components: {
    SalaryApproval,
  },
  data() {
    return {
      singleSelect: false,
      showRejectModal: false,
      tabSelected: 1,
      loanApprovalHeaders: [
        { text: 'Name', value: 'loanTypeName' },
        { text: 'Loan creator', value: 'idCreatedBy' },
        { text: 'Loan status', value: 'approval[0].hasApproved' },
        { text: 'Total loan', value: 'totalLoan', align: 'right' },
        { text: 'Action', value: 'action', align: 'right' },
      ],
      postApproval: [],
      showAlert: false,
      dialog: false,
      postSelected: {
        channels: [],
      },

      loanSubmissionsSelected: [],
    }
  },
  computed: {
    user() {
      return this.$store.getters['auth/getUser']
    },
    allCampaigns() {
      return this.$store.getters['campaign_manager/getAllCampaignsData']
    },
    allLoanSubmissions() {
      return this.$store.getters['manageLoan/getLoanSubsNeedMyApproval']
    },
    loanSubmissionsNeedApproval() {
      return this.$store.getters['manageLoan/getLoanSubsNeedMyApproval']
    },
    loanSubmissionIDsSelected() {
      return this.loanSubmissionsSelected.map(loanSub => loanSub.id)
    },
  },
  async created() {
    await this.getLoanSubmissionsNeedMyApproval()

    await this.$store.dispatch('manageApproval/getDisbursementsNeedApproval', { user: this.user })
  },
  methods: {
    async getLoanSubmissionsNeedMyApproval() {
      await this.$store.dispatch('manageLoan/getLoanSubmissionsNeedMyApproval', {
        sub_id: this.user.sub_id,
        input_data: { my_user_data: this.user },
      })
    },
    goBackCampaign() {
      this.$router.back()
    },
    async singleApproveLoanSubmission(ID) {
      await this.$store.dispatch('manageApproval/singleApproveLoanSubmission', {
        sub_id: this.user.sub_id,
        input_data: { ID, my_user_data: this.user },
      })
      await this.getLoanSubmissionsNeedMyApproval()
    },
    async singleRejectLoanSubmission(ID) {
      await this.$store.dispatch('manageApproval/singleRejectLoanSubmission', {
        sub_id: this.user.sub_id,
        input_data: { ID, my_user_data: this.user },
      })
      await this.getLoanSubmissionsNeedMyApproval()
    },
    async bulkApproveLoanSubmission() {
      await this.$store.dispatch('manageApproval/bulkApproveRejectLoanSubmission', {
        sub_id: this.user.sub_id,
        input_data: { ID: this.loanSubmissionIDsSelected, my_user_data: this.user, command: 'Approved' },
      })
      await this.getLoanSubmissionsNeedMyApproval()
    },
    async bulkRejectLoanSubmission() {
      await this.$store.dispatch('manageApproval/bulkApproveRejectLoanSubmission', {
        sub_id: this.user.sub_id,
        input_data: { ID: this.loanSubmissionIDsSelected, my_user_data: this.user, command: 'Rejected' },
      })
      await this.getLoanSubmissionsNeedMyApproval()
    },
    formatToIDRCurrency(number) {
      return new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
      }).format(number)
    },
    findMyApprovalStatus(array) {
      const foundApprovalStatus = array.find(e => e.sub_id === this.user.sub_id)

      return foundApprovalStatus
    },
  },
}
</script>
<style scoped>
.v-btn {
  background-color: 'red';
  color: '#fff';
}
.v-tab {
  text-transform: none !important;
}
</style>
