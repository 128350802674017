var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.disbursementsNeedApproval,"item-key":"id"},scopedSlots:_vm._u([{key:"item.no",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.disbursementsNeedApproval.findIndex(function (disbursement) { return disbursement.id === item.id; }) + 1)+". ")]}},{key:"item.disbursementDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(new Date(item.disbursementDate - new Date().getTimezoneOffset() * 60 * 1000)).format('DD MMM YYYY HH:mm'))+" ")]}},{key:"item.employeeCount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.employees.length)+" ")]}},{key:"item.totalAmount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formattedCurrency(item.disbursementTotalAmount))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-col',{staticClass:"my-4"},[_c('v-btn',{staticClass:"me-6 text-capitalize",attrs:{"outlined":"","color":"primary"},on:{"click":function($event){return _vm.toDisbursementDetail(item.employeeGroupId, item.id)}}},[_vm._v(" View detail ")]),_c('v-btn',{staticClass:"me-3 text-capitalize",attrs:{"outlined":"","color":"primary"},on:{"click":function($event){return _vm.updateStatus(item.id, 'approve')}}},[_vm._v(" Approve ")]),_c('v-btn',{staticClass:"text-capitalize",attrs:{"outlined":"","color":"error"},on:{"click":function($event){return _vm.updateStatus(item.id, 'reject')}}},[_vm._v(" Reject ")])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }