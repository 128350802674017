<template>
  <v-data-table
    :headers="headers"
    :items="disbursementsNeedApproval"
    item-key="id"
    class="elevation-1"
  >
    <template v-slot:item.no="{ item }">
      {{ disbursementsNeedApproval.findIndex((disbursement) => disbursement.id === item.id) + 1 }}.
    </template>

    <template v-slot:item.disbursementDate="{ item }">
      {{ $moment(new Date(item.disbursementDate - new Date().getTimezoneOffset() * 60 * 1000)).format('DD MMM YYYY HH:mm') }}
    </template>

    <template v-slot:item.employeeCount="{ item }">
      {{ item.employees.length }}
    </template>

    <template v-slot:item.totalAmount="{ item }">
      {{ formattedCurrency(item.disbursementTotalAmount) }}
    </template>

    <template v-slot:item.action="{item }">
      <v-row>
        <v-col class="my-4">
          <v-btn
            outlined
            class="me-6 text-capitalize"
            color="primary"
            @click="toDisbursementDetail(item.employeeGroupId, item.id)"
          >
            View detail
          </v-btn>

          <v-btn
            outlined
            class="me-3 text-capitalize"
            color="primary"
            @click="updateStatus(item.id, 'approve')"
          >
            Approve
          </v-btn>

          <v-btn
            outlined
            color="error"
            class="text-capitalize"
            @click="updateStatus(item.id, 'reject')"
          >
            Reject
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: 'SalaryApproval',
  data() {
    return {
      headers: [
        { text: 'No', value: 'no' },
        { text: 'Disbursement date', value: 'disbursementDate' },
        { text: 'Employee', value: 'employeeCount' },
        { text: 'Amount', value: 'totalAmount' },
        { text: 'Action', value: 'action', align: 'right' },
      ],
    }
  },
  computed: {
    user() {
      return this.$store.getters['auth/getUser']
    },
    disbursementsNeedApproval() {
      return this.$store.getters['manageApproval/getDisbursementsNeedApproval']
    },
  },
  async created() {
    await this.$store.dispatch('manageApproval/getDisbursementsNeedApproval', { user: this.user })
  },
  methods: {
    formattedCurrency(number) {
      return new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(number)
    },
    async toDisbursementDetail(employeeGroupId, disbursementId) {
      await this.$store.dispatch('manageSalary/getDisbursementById', { sub_id: this.user.sub_id, id: disbursementId })
      this.$router.push(`/manage-salary/employee-group/${employeeGroupId}/disbursement/${disbursementId}`)
    },
    async updateStatus(disbursementId, action) {
      await this.$store.dispatch('manageApproval/updateDisbursementApprovalStatus', {
        sub_id: this.user.sub_id,
        disbursementId,
        approver: this.user,
        action,
      })
      this.$store.dispatch('manageApproval/getDisbursementsNeedApproval', { user: this.user })
    },
  },
}
</script>

<style lang="scss" scoped>
</style>